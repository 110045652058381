import React from 'react';
import styles from './avatar.module.scss';

export const ResumeAsideAvatar = props => (
  <div className={styles.avatar}>
    <img
      className="avatar avatar-195"
      src="/images/tl.jpg"
      srcSet="/images/tl.jpg 2x"
      alt="Tomasz Libich"
      width="150"
      height="150"
    />
  </div>
);
