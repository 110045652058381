import clients from './clients';

export const data = {
  aside: {
    sections: [
      {
        id: 'intro',
        title: 'Front End Developer',
        description:
          'Extensively experienced in end to end FE development process, including FE architecture design, coding, testing, builds and CI/CD pipeline deployments.'
      },
      {
        id: 'clients',
        title: 'Worked for',
        classes: ['cv-logos-section']
      },
      {
        id: 'skills',
        title: 'Proficiency',
        skills: [
          'ReactJS',
          'Angular',
          'NodeJS',
          'Express',
          'REST',
          'GraphQL',
          'Amazon Web Services',
          'Serverless Architecture',
          'AngularJS',
          'GatsbyJS',
          'NextJS',
          'KnockoutJS',
          'BackboneJS',
          'jQuery',
          'Jest',
          'Karma / Mocha',
          'Jasmine',
          'Protractor',
          'Puppeteer',
          'Grunt',
          'Gulp',
          'Webpack',
          'HTML5 / XHTML1.0 / HTML4',
          'CSS, Sass, Less'
        ]
      }
    ]
  },
  main: {
    info: {
      name: 'Tomasz Libich',
      contact: [
        'www.tomaszlibich.com',
        '+44 7498 065 081',
        't.libich@gmail.com'
      ]
    }
  }
};
