export const trainings = [
  {
    title: 'Pluralsight, online',
    time: 'January 2020 – February 2021',
    description: '',
    points: [
      'JavaScript Security: Best Practices',
      'Automating AWS Operations with the AWS CLI',
      'Getting Started with Docker',
      'Docker and Kubernetes: The Big Picture'
    ]
  },
  {
    title: 'Pluralsight, online',
    time: 'September 2019 – November 2019',
    description: '',
    points: [
      'AWS Developer: Getting Started',
      'AWS Developer: The Big Picture',
      'GraphQL: The Big Picture',
      'Using React Hooks'
    ]
  },
  {
    title: 'Pluralsight, online',
    time: 'March 2014 – February 2016',
    description: '',
    points: [
      'Accelerating Through Angular 2',
      'ES2015 – The Future of JavaScript to come',
      'JavaScript Best Practices',
      'Real-time Web with Node.js',
      'Shaping up with AngularJS',
      'Staying sharp with AngularJS',
      'Powering Up with React',
      'Building Blocks of Express.js',
      'Flying Through Python',
      'Chrome DevTools',
      'Fundamentals of Design',
      'Journey into Mobile',
      'Git Real',
      'Try Git',
      'Try Python',
      'Adventures in Web Animations'
    ]
  },
  {
    title: 'David Bernstein, Net Objectives, London UK',
    time: 'January 2014',
    description: 'Design Patterns'
  },
  {
    title: 'Samuel Bucholtz, Microsoft, London UK',
    time: 'November 2013',
    description: 'RESTful APIs Security Threat Modeling'
  },
  {
    title: 'Jennifer Perret, Microsoft, London UK',
    time: 'October 2013',
    description: 'Exploring Windows Azure'
  },
  {
    title: 'Jeffrey Richter, Wintellect, London UK',
    time: 'July 2013',
    description: 'C# and CLR'
  },
  {
    title: 'Bill Hanlon, Engineering Excellence, Microsoft, London UK',
    time: 'May 2013',
    description: 'TDD – Test Driven Programming'
  },
  {
    title: 'Bill Hanlon, Engineering Excellence, Microsoft, London UK',
    time: 'May 2013',
    description: 'Writing Maintainable Code'
  },
  {
    title: 'Dan Greening, Skype, Prague, Czech Republic',
    time: 'February 2013',
    description: 'Strategic Problem Solving Teams'
  },
  {
    title: 'Scrum Alliance, London, UK',
    time: 'November 2012',
    description: 'Certified Scrum Master'
  },
  {
    title: 'GbDirect, London, UK',
    time: 'November 2006',
    description: 'Advanced CSS/CSS2'
  },
  {
    title: 'GbDirect, London, UK',
    time: 'November 2006',
    description: 'CSS Fundamentals'
  }
];
