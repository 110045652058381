import React, { useState } from 'react';

import { Clients } from '../common/aside/clients';
import Link from 'gatsby-link';
import { ResumeAsideAvatar } from '../modules/Resume/Aside/avatar';
import { ResumeAsideSection } from '../modules/Resume/Aside/section';
import { ResumeMainEducation } from '../modules/Resume/Main/Education';
import { ResumeMainExperience } from '../modules/Resume/Main/Experience';
import { ResumeMainInfo } from '../modules/Resume/Main/Info';
import { ResumeMainNonIt } from '../modules/Resume/Main/NonIt';
import { ResumeMainSkills } from '../modules/Resume/Main/Skills';
import { ResumeMainTrainings } from '../modules/Resume/Main/Trainings';
import classnames from 'classnames';
import { data as resumeData } from '../data/resume';
import styles from './resume.module.scss';

function onPrintClick(setIsPrint) {
  setIsPrint(true);
  const body = document.getElementsByTagName('body')[0];

  body.style = 'background: white; margin: 0; padding: 0;';

  setTimeout(() => {
    window.print();
  }, 500);
}

function getCustomComponent(id) {
  const customComponents = {
    clients: Clients
  };

  return customComponents[id] || null;
}

const Resume = () => {
  const { aside, main } = resumeData;
  const [isPrint, setIsPrint] = useState(false);

  return (
    <div
      className={classnames(
        { [`${styles.wrapper}`]: true },
        { [`${styles.print}`]: isPrint }
      )}
    >
      <div className={styles.resume}>
        <aside>
          <ResumeAsideAvatar />
          <div className={styles.sections}>
            {aside.sections.map((section, index) => {
              const {
                id,
                title,
                classes,
                description,
                clients,
                skills
              } = section;

              return (
                <ResumeAsideSection
                  key={index}
                  title={title}
                  description={description}
                  classes={classes}
                  Custom={getCustomComponent(id)}
                  skills={skills}
                />
              );
            })}
          </div>
        </aside>
        <main>
          <ResumeMainInfo data={main.info} isPrint={isPrint} />
          <ResumeMainSkills isPrint={isPrint} />
          <ResumeMainExperience isPrint={isPrint} />
          <ResumeMainTrainings isPrint={isPrint} />
          <ResumeMainEducation isPrint={isPrint} />
          <ResumeMainNonIt isPrint={isPrint} />
        </main>
      </div>

      {!isPrint && (
        <footer>
          <nav>
            <Link to="/">Back to home</Link>
          </nav>
          <div className={styles.actions}></div>
        </footer>
      )}
      {!isPrint && (
        <button
          className={classnames(['btn', 'btn-primary', styles.btnPrint])}
          onClick={() => onPrintClick(setIsPrint)}
        >
          Print
        </button>
      )}
    </div>
  );
};

export default Resume;
