import React from 'react';
import classnames from 'classnames';
import styles from './info.module.scss';

export const ResumeMainInfo = props => (
  <div
    className={classnames([
      styles.info,
      { [`${styles.print}`]: props.isPrint }
    ])}
  >
    <h1>{props.data.name}</h1>
    <div className={styles.contact}>
      {props.data.contact.map((detail, index) => (
        <span key={index}>{detail}</span>
      ))}
    </div>
    <div className={styles.intro}>
      Experienced <strong>UI / front-end web developer</strong> with proven
      commercial experience and professional trainings. Passionate about
      adapting <strong>software engineering principles</strong> into the
      development of front-end web applications as well as{' '}
      <strong>coding standards</strong>, <strong>best practices</strong> and{' '}
      <strong>design patterns</strong>.
    </div>
  </div>
);
