import React from 'react';
import classnames from 'classnames';
import styles from './skills.module.scss';

const date = new Date();
const currentYear = date.getFullYear();
const experienceYears = currentYear - 2006;

export const ResumeMainSkills = props => (
  <div
    className={classnames([
      styles.skills,
      { [`${styles.print}`]: props.isPrint }
    ])}
  >
    <h3>SKILLS</h3>
    <p className={styles.first}>
      Over {experienceYears} years of UI / Front End development in an
      international commercial environment, including an experience in leading
      teams and end customers focused interactions.
    </p>
    <p>
      Intensively involved in end-to-end development process - from writing the
      code and automated tests, through application builds and gated-check-ins
      via CI / CD pipelines.
    </p>
    <h4>Technical experience</h4>
    <ul>
      <li>
        building <strong>web apps</strong> using both native JS and modern UI
        frameworks &amp; libraries (<strong>ReactJS</strong>,{' '}
        <strong>Angular</strong>, AngularJS, KnockoutJS, BackboneJS, GatsbyJS,
        NextJS, jQuery) with <strong>NodeJS</strong> and{' '}
        <strong>ExpressJS</strong> on the back-end side within AWS, involving
        best practices and coding standards
      </li>
      <li>
        <strong>Amazon Web Services</strong>, Serverless Architecture, Lambda,
        S3, CloudFront, Route53, SNS, SES
      </li>
      <li>
        <strong>RESTful APIs</strong> or <strong>GraphQL</strong> endpoints
      </li>
      <li>
        <strong>TDD</strong> using Jest, Karma / Mocha, Jasmine, Protractor,
        Puppeteer, Grunt, Gulp, Webpack
      </li>
      <li>
        <strong>HTML5</strong>, XHTML1.0, HTML4, CSS, CSS2, CSS3, Sass / Scss,
        Less
      </li>
      <li>
        creating <strong>cross-browser compatible</strong> code, effective for
        SEO as well as cross-client compatible newsletters
      </li>
    </ul>
    <p>
      Certified <strong>Scrum Master</strong>, experienced and passionate about{' '}
      <strong>Agile/Scrum</strong>
      methodologies &amp; tools, backlogs with <strong>Jira</strong>, Bugzilla
      and other project tracking tools.
    </p>
    <p>
      Experienced in working with various control version policies, workflows
      and processes (<strong>Git</strong>, TFS, SVN).
    </p>
    <p>
      Strong <strong>analytical</strong> skills, enthusiasm for{' '}
      <strong>troubleshooting</strong> and <strong>problem solving</strong>.
      Soft interpersonal skills, including an effective work with people within
      the team, other IT departments and/or outside of the organisation.
    </p>
    <p>
      Working knowledge of <strong>microservices</strong>, <strong>C#</strong>,
      PHP with MySQL as well as <strong>web design tools</strong> (Photoshop,
      Sketch).
    </p>
  </div>
);
