import React from 'react';
import classnames from 'classnames';
import styles from './education.module.scss';

export const ResumeMainEducation = props => (
  <div
    className={classnames([
      styles.education,
      { [`${styles.print}`]: props.isPrint }
    ])}
  >
    <h3>EDUCATION</h3>
    <h4>University of Silesia, Katowice, Poland</h4>
    <em>October 1998 - June 2003</em>
    <strong>Master of Arts degree in Political Sciences</strong>
  </div>
);
