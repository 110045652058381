import React from 'react';
import classnames from 'classnames';
import styles from './nonit.module.scss';

export const ResumeMainNonIt = props => (
  <div
    className={classnames([
      styles.nonit,
      { [`${styles.print}`]: props.isPrint }
    ])}
  >
    <h3>NON IT RELATED</h3>
    <p>
      Over 10 years of a successful journalist career as a reporter, editor,
      newsroom manager and news reader in a regional radio and TV broadcasting
      channels.
    </p>
    <em>
      This path of the career has been abandoned due to a need of a bigger
      stability and predictability before starting a family. A natural choice
      was to switch to a professional programming / web development, since it
      was a personal hobby for years before.
    </em>
  </div>
);
