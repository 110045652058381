import React from 'react';
import classnames from 'classnames';
import styles from './trainings.module.scss';
import { trainings } from '../../../data/trainings';

export const ResumeMainTrainings = props => (
  <div
    className={classnames([
      styles.trainings,
      { [`${styles.print}`]: props.isPrint }
    ])}
  >
    <h3>COMPLETED TRAININGS</h3>
    {trainings.map((training, index) => {
      const { title, time, description, points } = training;
      return (
        <div key={index} className={styles.training}>
          <strong>{training.title}</strong>
          <em>{time}</em>
          {description && <p>{description}</p>}
          {!!points && !!points.length && (
            <ul>
              {points.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          )}
        </div>
      );
    })}
  </div>
);
