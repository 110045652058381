import React from 'react';
import { ResumeMainJob } from './Job';
import classnames from 'classnames';
import { jobs } from '../../../data/jobs';
import styles from './experience.module.scss';

export const ResumeMainExperience = props => {
  return (
    <div
      className={classnames([
        styles.experience,
        { [`${styles.print}`]: props.isPrint }
      ])}
    >
      <h3>WORK EXPERIENCE</h3>
      <ul className={styles.jobslist}>
        {jobs.map((job, index) => (
          <li key={index} className={styles.jobitem}>
            <ResumeMainJob job={job} />
          </li>
        ))}
      </ul>
    </div>
  );
};
