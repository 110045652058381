import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import styles from './job.module.scss';

export const ResumeMainJob = props => {
  const { job } = props;
  const {
    location,
    company,
    position,
    start,
    end,
    responsibilities,
    logo
  } = job;
  const startYear = moment(start).format('YYYY');
  const startMonth = moment(start).format('MMM');
  const endYear = end ? moment(end).format('YYYY') : '';
  const endMonth = end ? moment(end).format('MMM') : 'now';

  return (
    <div className={styles.job}>
      <div
        className={styles.head}
        style={{ backgroundImage: `url(/images/logos/${logo})` }}
      >
        <h4>
          {company}, {location}
        </h4>
        <em>
          {startMonth} {startYear} - {endMonth} {endYear}
        </em>
        <strong>{position}</strong>
      </div>
      <ul className={styles.responsibilities}>
        {responsibilities.map((responsibility, index) => (
          <li key={index}>{responsibility}</li>
        ))}
      </ul>
    </div>
  );
};
