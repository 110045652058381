export const jobs = [
  {
    start: '2020-01-01T00:00:00.000Z',
    end: '2021-09-01T00:00:00.000Z',
    position: 'Technical Lead Engineer (Front-End)',
    location: 'London, UK',
    logo: 'natwest-white.png',
    id: 'job-natwest',
    company: 'NatWest Group',
    responsibilities: [
      'technically designing and building a Front-End e-banking SPA for end customers using Angular 9, StencilJS for standalone web components, Storybook, Jest and Cypress',
      'HTML5, CSS2/CSS3, SASS, Bootstrap, Material UI, Webpack',
      'architecting and implementing Path to Live using best CI/CD practices using AWS resources',
      'designing coding standards for the dev team with best practices, engineering principles and design patterns',
      "supporting the development team and aiding technologies being selected to be aligned with the business' goals and vision of the project"
    ]
  },
  {
    start: '2021-03-01T00:00:00.000Z',
    position: 'Senior Front-End Developer',
    location: 'New York, USA (remote)',
    logo: 'glean-200.png',
    id: 'job-glean',
    company: 'Glean Analytics Inc.',
    responsibilities: [
      'developing a Big Data driven smart accounts payable tool that analyzes invoices at the line-item level using React, GraphQL, NodeJS and Jest',
      'HTML5, CSS2/CSS3, SASS, Webpack',
      'working on the ongoing coding standards improvements for the dev team with best practices, engineering principles and design patterns'
    ]
  },
  {
    start: '2019-06-01T00:00:00.000Z',
    end: '2020-01-01T00:00:00.000Z',
    location: 'London, UK',
    position: 'Senior Front-End Developer',
    logo: 'deutsche-bank-white.png',
    id: 'job-db',
    company: 'Deutsche Bank GFT',
    responsibilities: [
      're-platforming a Big Data driven Profit&Loss web app for bank managers from AngularJS + Mocha / Karma + Protractor to ReactJS + Redux with Jest and Puppeteer',
      'HTML5, CSS2/CSS3, NodeJS, Webpack',
      'influencing and supporting process of Front-End code quality improvements (coding standards, design patterns, engineering principles) among dev teams as a designated Efficiency Ambassador'
    ]
  },
  {
    start: '2019-01-01T00:00:00.000Z',
    end: '2019-06-01T00:00:00.000Z',
    location: 'London, UK',
    position: 'Senior Front-End Software Engineer',
    logo: 'gfk-200.png',
    id: 'job-gfk',
    company: 'GfK',
    responsibilities: [
      'developing a Big Data driven e-commerce ecosystem applications for business customers using ReactJS + Redux with GraphQL',
      'HTML5, CSS2/CSS3 with Flexbox, Typescript, NodeJS, Webpack',
      'Jest unit tests',
      'Supporting process of Front-End code quality among development teams (coding standards, design patterns, engineering principles)'
    ]
  },
  {
    start: '2018-07-01T00:00:00.000Z',
    end: '2019-01-01T00:00:00.000Z',
    location: 'London, UK',
    position: 'Senior Software Engineer, Front-End',
    logo: 'telegraph-square.jpg',
    id: 'job-telegraph',
    company: 'The Telegraph',
    responsibilities: [
      "leading a project of re-platforming The Telegraph's Newsroom Authoring web app from legacy (BackboneJS) into a modern technology stack (NodeJS and ReactJS)",
      'developing solutions for AWS lambda based / NodeJS API integrations between different platforms and tools being used on the daily basis within The Telegraph: authoring tools <-> Trello <-> Slack <-> web plugins',
      'HTML5, CSS2/CSS3 with Flexbox, SASS, native Javascript, ReactJs, NodeJS',
      'Jest unit tests',
      'introducing and maintaining process of Front-End code quality improvements for Telegraph applications and plugins (TDD, Jest unit tests, coding standards + design patterns, EsLint)'
    ]
  },
  {
    start: '2017-11-01T00:00:00.000Z',
    end: '2018-07-01T00:00:00.000Z',
    location: 'London, UK',
    position: 'Senior Web Engineer',
    logo: 'asos-white.png',
    id: 'job-asos',
    company: 'ASOS',
    responsibilities: [
      're-platforming ASOS web applications from legacy (C# + KnockoutJS) into a modern technology stack (NodeJS and ReactJS)',
      'HTML5, CSS2/CSS3 with Flexbox, SASS, native Javascript, ReactJs, NodeJS, KnockoutJS',
      'Jest & Jasmine unit tests',
      'Helping to establish and maintain process of Front-End code quality improvements within the team (TDD, unit tests, coding standards, design patterns, EsLint)'
    ]
  },
  {
    start: '2017-01-01T00:00:00.000Z',
    end: '2018-11-01T00:00:00.000Z',
    location: 'London, UK',
    position: 'Senior Front End Developer',
    logo: 'aimia-white.png',
    id: 'job-aimia',
    company: 'Aimia Inc',
    responsibilities: [
      'developing a Big Data based e-commerce application for business customers using AngularJS 1.5',
      'HTML5, CSS2/CSS3 with Flexbox, native Javascript, NodeJS, AJAX, JSON/JSONP',
      'Karma / Mocha unit tests, Protractor E2E tests',
      'Maintaining process of Front-End code quality within the team (coding standards, design patterns, EsLint, Karma unit tests, TDD)'
    ]
  },
  {
    start: '2016-05-01T00:00:00.000Z',
    end: '2017-01-01T00:00:00.000Z',
    location: 'London, UK',
    position: 'Senior Front End Developer',
    logo: 'ft-200.png',
    id: 'job-ft',
    company: 'Financial Times',
    responsibilities: [
      'designing architecture and developing an end to end NodeJS back-end and front-end solutions for annotated data visualisations using Angular2, ReactJS, AureliaJS and D3 frameworks / libraries',
      "designing and implementing a Gulp and Webpack task runners based solutions to deliver a full release process from developer's local environment, through gated commits to repository, through CircleCI environment up to Docker / Heroku UAT/Prod pipelines",
      'HTML5, CSS2/CSS3 with Flexbox, native Javascript, AureliaJS, ReactJS, NodeJS, AJAX, JSON/JSONP',
      'Karma / Mocha unit tests, Protractor E2E tests',
      'maintaining process of Front-End code quality management within the team (coding standards, design patterns, EsLint, Mocha / Karma unit tests, TDD)'
    ]
  },
  {
    start: '2014-04-01T00:00:00.000Z',
    end: '2016-11-01T00:00:00.000Z',
    location: 'Hatfield, UK',
    position: 'Lead Front-End Developer',
    logo: 'deutsche-telekom-white.png',
    id: 'job-dt',
    company: 'Deutsche Telekom UK',
    responsibilities: [
      'introducing and managing process of code quality management within the team (coding standards, design patterns, jsLint, Mocha / Karma unit tests, TDD)',
      'architecture design and development of an end to end front-end solution for a new high traffic customer services portal using AngularJS framework',
      "designing and implementing a Grunt task runner based solution to deliver a full release process from developer's local environment, through gated commits to repository and CI environment up to UAT/Prod environments",
      'HTML5, CSS2/CSS3 with Flexbox, native Javascript, AngularJS, NodeJS, AJAX, JSON/JSONP',
      'Karma / Mocha unit tests, Protractor E2E tests'
    ]
  },
  {
    start: '2014-11-01T00:00:00.000Z',
    end: '2016-04-01T00:00:00.000Z',
    location: 'Welvyn G. City, UK',
    position: 'Javascript Team Lead',
    logo: 'tesco-white.png',
    id: 'job-tesco',
    company: 'Tesco Technologies',
    responsibilities: [
      'introducing and managing process of code quality improvements within the team (coding standards, design patterns, jsLint, Jasmine unit tests, TDD)',
      'architecture desing and development of general front-end solutions for Tesco Direct and Tesco kiosks (an own custom MV* API solution based on BackboneJS – built from scratch and tailored for Tesco eCommerce platform specifics)',
      'working at GMO UI Buildkit team (Tesco Direct flagship product)',
      'focusing on responsive and performance solutions for high profile, high traffic eCommerce platform',
      'HTML5, CSS2/CSS3, Javascript, jQuery, AJAX, JSON/JSONP',
      'AMD (Asynchronous Module Definition) with RequireJS',
      'integrating front-end layer with ATG back-end platform',
      'Omniture tracking system',
      'GruntJS based build process',
      'focused on fully responsive, semantic and cross-browser compatible code'
    ]
  },
  {
    start: '2012-04-01T00:00:00.000Z',
    end: '2014-04-01T00:00:00.000Z',
    location: 'London, UK',
    position: 'Software Development Engineer 2',
    logo: 'microsoft-white.png',
    id: 'job-microsoft',
    company: 'Microsoft, Skype',
    responsibilities: [
      'working at Web Apps team (Skype experience for browsers – providing Skype video calls experience for Microsoft flagship products: Live.com, new Outlook.com Bing.com and MSN client)',
      'building web app that allows Skype users to communicate between different web platforms',
      'HTML5, CSS2/CSS3, Javascript, jQuery, AJAX, JSON/JSONP, HTTP/HTTPS APIs',
      'semantic and cross-browser compatible code',
      'Internationalization related front end solutions',
      'Jasmine unit tests',
      'AMD (Asynchronous Module Definition) with RequireJS',
      'Ant build process, QuickBuild build process, Omniture tracking system',
      'Photoshop, Fireworks',
      'Certified Scrum Master, Jira'
    ]
  },
  {
    start: '2010-12-01T00:00:00.000Z',
    end: '2012-04-01T00:00:00.000Z',
    location: 'London, UK',
    position: 'UI Developer',
    logo: 'thefa-white.png',
    id: 'job-thefa',
    company: 'The Football Association',
    responsibilities: [
      "developing company's flagship websites based on wireframes and PSD mock-ups",
      'HTML5, XHTML1.0, CSS, CSS2, CSS3, Javascript, jQuery, AJAX, XML+XSLT',
      'focused on semantic and cross-browser compatible code',
      'working on full accessibility checkup, AA accreditation for The FA websites',
      'SEO improvements related front-end code solutions',
      'Extensive use of social network plugins: Facebook and Twitter',
      'Photoshop, Microsoft Visual Studio',
      'Agile (Scrum), TFS (Team Foundation Server), Trello, Jira '
    ]
  },
  {
    start: '2010-09-01T00:00:00.000Z',
    end: '2010-12-01T00:00:00.000Z',
    position: 'Front End Developer',
    location: 'London, UK',
    id: 'job-virtualuniverse',
    company: 'Virtual Universe',
    responsibilities: [
      "working on re-design and improvements at company's websites based on wireframes and PSD mock-ups",
      'XHTML1.0, CSS, CSS2, Javascript, jQuery, Prototype js',
      'focused on semantic and cross-browser compatible code',
      'SEO improvement front-end code solutions',
      'Photoshop, SVN, Eclipse',
      'Agile (Scrum), Jira'
    ]
  },
  {
    start: '2007-11-01T00:00:00.000Z',
    end: '2010-08-01T00:00:00.000Z',
    location: 'Bielsko-B, Poland',
    position: 'Front End Lead',
    logo: 'grapecom-square.png',
    id: 'job-grapecom',
    company: 'Grapecom Software',
    responsibilities: [
      'leading a team of 4 (designer, front-end developer, 2 back-end developers)',
      'building number of whole websites from scratch (examples on my webpage)',
      'XHTML1.0, HTML4, CSS, CSS2, Javascript, jQuery, Smarty',
      'focused on semantic and cross-browser compatible code',
      'SEO front-end code solutions',
      'PHP4 procedural programming + MySQL',
      'Photoshop, SVN, PsPad'
    ]
  },
  {
    start: '2006-12-01T00:00:00.000Z',
    end: '2007-11-01T00:00:00.000Z',
    location: 'London, UK',
    position: 'Front End Developer',
    logo: 'fitbug-square.png',
    id: 'job-fitbug',
    company: 'Fitbug Ltd.',
    responsibilities: [
      "building the complete front end templates system for company's web portal in version 3.5",
      "building the complete front end templates system of company's portal in version 3",
      'focused on semantic and cross-browser compatible code',
      'XHTML1.0, CSS, CSS2, Javascript, jQuery, Smarty',
      'SEO front-end code solutions',
      'Photoshop, Visual Source Safe, SVN, PsPad, Zend Studio'
    ]
  }
];
