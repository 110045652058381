import React from 'react';
import classnames from 'classnames';
import styles from './section.module.scss';

export const ResumeAsideSection = ({
  Custom,
  classes,
  title,
  description,
  skills
}) => {
  const classNames = classes || [];

  return (
    <section className={classnames([styles.section, ...classNames])}>
      <h3>{title}</h3>
      <strong>{description}</strong>
      {Custom && <Custom classes={['cv-logos']} />}

      {!!skills && !!skills.length && (
        <div className={styles.skills}>
          {skills.map((skill, index) => (
            <span key={index}>{skill}</span>
          ))}
        </div>
      )}
    </section>
  );
};
